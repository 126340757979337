import React from 'react'

import { Link } from 'gatsby'
import RightArrow from '../components/SVG/RightArrow'
import Slider from '../components/Slider'
import downloadIcon from '../images/download-icon.svg'

const Intro = ({
  title,
  lead,
  covidNotice,
  imageUrl,
  imageAlt,
  imagePosition = 'primary',
  embed,
  slides,
  link,
  id,
}) => {
  return (
    <div
      id={id}
      className="mt-12 lg:pt-25 lg:mb-3 mb-20 lg:mb-40 px-3 lg:px-14"
    >
      <div className="lg:flex lg:-mx-2">
        {title && (
          <div className="lg:w-1/2 lg:px-2">
            <h1 className="mb-12 lg:mb-20 lg:w-4/5 text-4xl font-light leading-tight text-grey">
              {title}
            </h1>
            {imageUrl && imageAlt && imagePosition === 'primary' && (
              <img
                className="mb-10 lg:mb-0 lg:w-3/4"
                src={imageUrl}
                alt={imageAlt}
              />
            )}
            {embed && (
              <div className={`lg:w-3/4 ${link ? 'mb-10' : 'mb-10 lg:mb-0'}`}>
                <div
                  className="ratio ratio-9/16"
                  dangerouslySetInnerHTML={{ __html: embed }}
                />
              </div>
            )}
            {link && (
              <div className="mb-10 lg:mb-0 lg:w-3/4">
                <a
                  href={link.url}
                  className="text-charcoal bg-green cursor-pointer outline-none hover:opacity-75 focus:opacity-75 transition p-4 flex items-center justify-between mb-5 no-underline"
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>{link.title}</p>
                  <img
                    className="ml-2"
                    src={downloadIcon}
                    alt="Download icon"
                  />
                </a>
              </div>
            )}
          </div>
        )}
        <div className={`lg:w-1/2 lg:px-2 ${slides ? 'mb-12 lg:mb-20' : ''}`}>
          {lead && (
            <div className={slides ? 'mb-12 lg:mb-20' : ''}>
              <div
                className="rte text-lg leading-lg"
                dangerouslySetInnerHTML={{ __html: lead }}
              />
              {imageUrl && imageAlt && imagePosition === 'secondary' && (
                <img
                  className="block w-48 mt-10"
                  src={imageUrl}
                  alt={imageAlt}
                />
              )}
              {covidNotice && (
                <Link
                  className="text-black bg-green hover:opacity-75 focus:opacity-75 outline-none transition p-4 flex items-center no-underline mt-10"
                  to="covid19"
                >
                  <span className="flex-1 mr-2">
                    Do you have an upcoming surgery at Forté Health? Please
                    click here to read our COVID-19 requirements.
                  </span>
                  <RightArrow className="svg-charcoal" />
                </Link>
              )}
            </div>
          )}
          {slides && <Slider items={slides} />}
        </div>
      </div>
    </div>
  )
}

export default Intro
