import React from 'react'
import Slider from "react-slick"

const SliderComponent = ({ items }) => {
  
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
  }
  
  return (
    <Slider {...settings}>
      {items
        .filter(item => item.slides_image.url)
        .map((item, index) => (
        <div key={index}>
          <img src={item.slides_image.url} alt={item.slides_image.alt} />
        </div>
      ))}
    </Slider>
  )
}

export default SliderComponent