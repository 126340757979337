import React from 'react'
import { graphql, Link } from 'gatsby'
import SEOPage from '../components/SEO/Page'

import RightArrow from '../components/SVG/RightArrow'

import Layout from '../components/layout'

import Hero from '../components/hero'
import Article from '../components/article'
import Intro from '../components/intro'

class IndexPage extends React.Component {
  render() {
    const { location, data } = this.props
    const homeContent = data.prismicHome.data
    const homeNews = data.allPrismicNewsArticle.edges

    var heroImgs = []
    var heroImgsMobile = []

    homeContent.hero.forEach(item => {
      heroImgs.push(item.desktop_image.url)
      heroImgsMobile.push(item.mobile_image.url)
    })

    return (
      <Layout>
        <SEOPage
          title={homeContent.title && homeContent.title}
          location={location}
        />
        <Hero heroImgs={heroImgs} heroImgsMobile={heroImgsMobile} />
        <Intro
          title={homeContent.lead_title}
          lead={homeContent.lead_text.html}
          covidNotice={true}
        />
        {homeNews && (
          <div id="news" className="lg:flex px-3 lg:px-14 mb-16 lg:mb-35">
            <div className="lg:w-1/4 flex flex-col justify-between lg:px-2 mb-12 ">
              {homeContent.secondary_title && (
                <h2 className="tracking-2xl text-2xl font-light leading-tight mb-4 text-grey w-48">
                  {homeContent.secondary_title}
                </h2>
              )}
              <Link className="link inline-block lg:hidden" to="news">
                <span className="text-black mr-2">View all</span>
                <RightArrow className="svg-charcoal" />
              </Link>
            </div>
            <div className="lg:w-3/4 text-lg leading-lg lg:px-2">
              <div className="md:flex flex-wrap md:-mx-2">
                {homeNews.map((item, i) => {
                  return (
                    <Article
                      key={i}
                      img={item.node.data.image.url}
                      alt={item.node.data.image.alt}
                      link={item.node.uid}
                      value={item.node.data.category.document[0].data.title}
                      title={item.node.data.title}
                      text={item.node.data.lead.text}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </Layout>
    )
  }
}

export default IndexPage

export const pageHomeQuery = graphql`
  query {
    prismicHome {
      data {
        title
        hero {
          desktop_image {
            localFile {
              childImageSharp {
                id
              }
            }
            url
          }
          mobile_image {
            localFile {
              childImageSharp {
                id
              }
            }
            url
          }
        }
        lead_title
        lead_text {
          html
          text
        }
        secondary_title
      }
    }
    allPrismicNewsArticle(
      sort: { fields: data___date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          uid
          first_publication_date(formatString: "MMMM Do YYYY")
          data {
            title
            lead {
              text
            }
            image {
              url
              alt
              localFile {
                childImageSharp {
                  id
                }
              }
            }
            body {
              html
            }
            category {
              uid
              document {
                data {
                  title
                  subtitle
                }
              }
            }
          }
        }
      }
    }
  }
`
