import React from 'react'

const RightArrow = props => (
  <svg width={23} height={11} {...props}>
    <g className="svg-fill">
      <path d="M0 5.077h22v1H0z" />
      <path d="M17.346.116L22.731 5.5l-.707.707L16.639.823z" />
      <path d="M16.748 10.286l5.384-5.384.707.707-5.384 5.385z" />
    </g>
  </svg>
)

export default RightArrow
