import React from 'react'
import { Link } from 'gatsby'

import RightArrow from '../SVG/RightArrow'

const Article = ({ title, text, link, value, img, alt, date }) => (
  <div className="w-full md:w-1/2 lg:w-1/3 md:px-2 mb-16 lg:mb-20 leading-lg tracking-lg">
    <div className="h-full flex flex-col group">
      <div className="relative ratio ratio-2/3 w-full mb-5">
        {img && <img src={img} alt={alt} />}
        <Link
          to={`/news/${link}`}
          className="cursor-pointer absolute pin-t pin-l bg-green-75 opacity-0 group-hover:opacity-100 w-full h-full transition flex items-end lg:items-center no-underline"
        >
          {value && (
            <div className="m-3 text-white text-sm absolute pin-l pin-t">
              <p className="mb-2 font-medium">{value}</p>
            </div>
          )}
          <div className="m-3 text-white text-sm absolute pin-l pin-b">
            <p className="mb-2 font-medium">Read more here</p>
            <RightArrow className="svg-white block" />
          </div>
        </Link>
      </div>
      <div className="flex flex-col justify-between h-full">
        <div>
          {date && <p className="text-sm text-black mb-6">{date}</p>}
          {title && (
            <h3 className="text-lg font-normal text-charcoal mb-2">{title}</h3>
          )}
          {text && <p className="text-sm text-black mb-6">{text}</p>}
        </div>
      </div>
      {link && (
        <Link className="text-sm font-medium text-black" to={`/news/${link}`}>
          Read more here
        </Link>
      )}
    </div>
  </div>
)

export default Article
